<template>
  <v-card v-bind="$attrs" flat class="image-annotator-v2">
    <v-card-title v-if="hasHeader">
      <slot name="header">
        <span>
          {{ attachment ? attachment.title || attachment.filename : '' }}
        </span>
      </slot>
    </v-card-title>
    <v-card-text>
      <v-row dense align="stretch" justify="space-between">
        <v-col
          cols="12"
          :md="showRightSide ? 8 : 12"
          sm="12"
          class="d-flex align-start flex-column justify-start"
        >
          <v-sheet
            color="transparent"
            outlined
            class="
              rounded-5
              d-flex
              align-start
              justify-start
              fullwidth
              py-5
              flex-column
            "
          >
            <div class="d-flex align-self-center justify-center">
              <v-btn-toggle
                dense
                mandatory
                v-show="!disableEditor && !readOnly && toggleAnnotations"
                class="ma-1"
                v-model="activeDrawingTool"
              >
                <v-btn
                  :color="activeDrawingTool === 'polygon' ? 'white' : 'primary'"
                  value="rect"
                >
                  <v-icon
                    :color="activeDrawingTool === 'rect' ? 'white' : 'primary'"
                  >
                    mdi-crop-square
                  </v-icon>
                </v-btn>
                <v-btn
                  :color="activeDrawingTool === 'rect' ? 'white' : 'primary'"
                  value="polygon"
                >
                  <v-icon
                    :color="
                      activeDrawingTool === 'polygon' ? 'white' : 'primary'
                    "
                  >
                    mdi-vector-polygon
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
              <v-btn
                height="36"
                @click="toggleAnnotations = !toggleAnnotations"
                :color="toggleAnnotations ? 'primary' : 'secondary'"
                depressed
                width="170"
                class="ma-1 text-none"
              >
                {{
                  toggleAnnotations ? `Hide Annotations` : `Show Annotations`
                }}
              </v-btn>
            </div>
            <v-sheet
              class="fullwidth d-flex align-center mt-3 justify-center"
              color="transparent"
              v-if="attachment"
            >
              <img
                class="mx-auto"
                style="max-height: 450px; width: auto; max-width: 100%"
                :src="attachment.url_inline"
                :id="attachment.uuid"
              />
            </v-sheet>
            <Empty min-height="500" v-else headline="No image found!"></Empty>
            <slot name="bottom"></slot>
          </v-sheet>
        </v-col>
        <v-col
          v-if="showRightSide"
          cols="12"
          md="4"
          sm="12"
          class="align-self-start"
        >
          <v-card flat outlined class="rounded-5">
            <v-card-text>
              <v-expansion-panels multiple v-model="openPanels">
                <v-expansion-panel
                  active-class="grey lighten-2 primary--text"
                  v-for="(item, index) in annoList"
                  :key="index"
                >
                  <v-expansion-panel-header
                    v-if="getTags(item).length === 0"
                    class="fw-700"
                  >
                    Annotation {{ index + 1 }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-header
                    v-else
                    class="d-flex align-center justify-start fw-700"
                  >
                    {{ getTags(item) }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-sheet
                      outlined
                      class="mb-1 pa-2 rounded-5"
                      v-for="body in item.body"
                      :key="body.created"
                    >
                      <div
                        class="
                          d-flex
                          fullwidth
                          flex-column
                          justify-start
                          align-start
                        "
                      >
                        <div class="primary--text fw-700">
                          From : {{ body.creator.name | ucwords }}
                        </div>
                        <div class="secondary--text fw-500">
                          Message : {{ body.value }}
                        </div>
                      </div>
                    </v-sheet>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Annotorious } from '@recogito/annotorious'
import '@recogito/annotorious/dist/annotorious.min.css'
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import ShapeLabelsFormatter from '@recogito/annotorious-shape-labels'
import BetterPolygon from '@recogito/annotorious-better-polygon'
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'ImageAnnotatorV2',
  props: {
    attachment: { type: Object, required: true },
    hasHeader: { type: Boolean, default: true },
    disableEditor: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    showRightSide: { type: Boolean, default: false }
  },
  data: () => ({
    annoList: [],
    activeDrawingTool: 'polygon',
    annoObject: null,
    selectedAnnotation: null,
    openPanels: [].length,
    toggleAnnotations: true
  }),
  watch: {
    attachment: {
      handler: function (val) {
        this.fetchFromServer(val)
      },
      immediate: true,
      deep: true
    },
    activeDrawingTool: {
      handler: function (val) {
        this.annoObject && this.annoObject.setDrawingTool(val)
      },
      immediate: true
    },
    selectedAnnotation: {
      handler: function (val) {
        this.openPanels = val
          ? [this.annoList.findIndex((i) => i.id === val.id)]
          : []
      },
      deep: true,
      immediate: true
    },
    toggleAnnotations(val) {
      this.annoObject.setVisible(val)
    }
  },
  mounted() {
    const vm = this
    this.annoObject = new Annotorious({
      image: vm.attachment.uuid || 'annotoriousdashcards',
      locale: 'auto',
      readOnly: this.readOnly,
      formatter: ShapeLabelsFormatter(),
      disableEditor: this.disableEditor,
      drawOnSingleClick: true,
      widgets: [
        'COMMENT',
        {
          widget: 'TAG',
          vocabulary: [
            'Wrong Image',
            'Wrong Icon',
            'Wrong Color',
            'Wrong Dimension'
          ]
        }
      ]
    })
    BetterPolygon(this.annoObject)
    this.annoObject.setAuthInfo({
      id: `${this.settings.apiHostBaseURL}/user/${this.user.username}`,
      displayName: this.user.fullname
    })
    if (this.annoObject) {
      this.annoObject.on('createAnnotation', (annotation) =>
        this.updateAnnotations('Annotation added')
      )
      this.annoObject.on('updateAnnotation', (annotation) =>
        this.updateAnnotations('Annotation updated')
      )
      this.annoObject.on('deleteAnnotation', (annotation) =>
        this.updateAnnotations('Annotation removed')
      )
      this.annoObject.on('selectAnnotation', (annonation) => {
        this.selectedAnnotation = annonation
      })
      this.annoObject.setDrawingTool('polygon')
    }
  },
  computed: {
    ...mapGetters(['user']),
    title() {
      return this.attachment
        ? this.attachment.title || this.attachment.filename
        : `Add annotations`
    }
  },
  methods: {
    updateAnnotations(message) {
      this.annoList = this.annoObject.getAnnotations()
      this.$nextTick(() => this.sendToServer(message))
    },
    sendToServer(message) {
      request
        .post(`api/attachments/annotation/${this.attachment.uuid}`, {
          annotations: this.annoList
        })
        .then(({ data }) => {
          this.appSnackbar(message)
        })
    },
    fetchFromServer(attachment) {
      if (this.annoObject || !attachment) return
      request
        .get(`api/attachments/annotation/${attachment.uuid}`)
        .then(({ data }) => {
          this.annoObject.setAnnotations(data || [])
          this.annoList = data || []
        })
    },
    getTags(annotation) {
      return annotation.body
        .filter((i) => i.purpose === 'tagging')
        .map((i) => i.value)
        .join(', ')
    }
  }
}
</script>

<style lang="css">
.image-annotator-v2 .r6o-editor,
.image-annotator-v2 .r6o-editor-inner {
  border-radius: 10px;
}
.image-annotator-v2 .r6o-widget.comment .r6o-lastmodified {
  color: #223a6b !important;
  font-weight: 700;
}
.image-annotator-v2 .r6o-editor .r6o-footer .r6o-btn:not(.outline) {
  background-color: #223a6b;
  color: #ffffff;
  border-radius: 5px;
}
.image-annotator-v2 .r6o-editor .r6o-footer .r6o-btn.outline {
  border-color: #223a6b;
  color: #223a6b;
  border-radius: 5px;
}
</style>
