<template>
  <v-card v-bind="$attrs" flat class="comments">
    <OverlayLoader :value="loading"></OverlayLoader>
    <v-card-title v-if="hasHeader">
      <slot name="header"></slot>
    </v-card-title>
    <v-card-text
      class="comments-body"
      :class="{ 'd-flex align-center': comments.length === 0 }"
      :style="`min-height:${bodyMinHeight}px;max-height:auto;`"
    >
      <v-sheet v-if="comments.length > 0">
        <v-row dense align="center" justify="center" v-if="next_page_url">
          <v-btn
            height="20"
            text
            color="primary mx-auto align-self-center"
            class="text-none fs-13"
            @click.stop="fetchMoreComments"
            :loading="loading_more"
          >
            View previous messages
          </v-btn>
        </v-row>
        <v-list
          dense
          class="overflow-y-auto"
          :min-height="bodyMinHeight"
          :max-height="bodyMaxHeight"
        >
          <template v-for="message in comments">
            <v-hover
              v-slot:default="{ hover }"
              v-if="message.causer.id !== user.id"
              :key="message.id"
            >
              <v-list-item class="pl-0">
                <v-overlay
                  opacity="0.1"
                  absolute
                  v-if="can_delete_comment(message)"
                  :value="hover"
                  class="d-flex justify-end"
                >
                  <v-btn
                    icon
                    small
                    color="red"
                    @click.stop="confirmDelete(message)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-overlay>
                <v-badge
                  bordered
                  bottom
                  color="success"
                  dot
                  offset-x="25"
                  offset-y="15"
                >
                  <v-list-item-avatar size="40">
                    <v-img
                      :lazy-src="settings.loader"
                      contain
                      :src="message.causer.image_url"
                    ></v-img>
                  </v-list-item-avatar>
                </v-badge>
                <v-list-item-content>
                  <v-list-item-title class="d-flex justify-space-between">
                    <span class="fw-900 fs-14 primary--text">
                      {{ message.causer.fullname }}
                    </span>
                    <span class="fw-500 grey--text">
                      {{ message.created_at | from_now }}
                    </span>
                  </v-list-item-title>
                  <div
                    v-if="message.body !== ''"
                    v-html="message.body"
                    class="d-flex pa-1 align-stretch fullwidth secondary--text"
                  ></div>
                  <div
                    v-if="message.attachments.length"
                    class="d-flex pa-1 align-center fullwidth"
                  >
                    <Attachment
                      :item="message.attachments[0]"
                      styles="max-width:90%; height:auto;max-height: 100px;"
                      prefer-thumb
                    ></Attachment>
                    <v-btn :href="message.attachments[0].url" icon>
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
            <v-hover v-slot:default="{ hover }" v-else :key="message.id">
              <v-list-item class="pr-0">
                <v-overlay
                  opacity="0.1"
                  absolute
                  v-if="can_delete_comment(message)"
                  :value="hover"
                  class="d-flex justify-start"
                >
                  <v-btn
                    icon
                    small
                    color="red"
                    @click.stop="confirmDelete(message)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-overlay>
                <v-list-item-content>
                  <v-list-item-title class="d-flex justify-space-between">
                    <span class="fw-500 grey--text">
                      {{ message.created_at | from_now }}
                    </span>
                    <span class="fw-900 fs-14 primary--text">
                      {{ message.causer.fullname }}
                    </span>
                  </v-list-item-title>
                  <div
                    v-if="message.body !== ''"
                    v-html="message.body"
                    class="
                      d-flex
                      align-stretch
                      fullwidth
                      secondary--text
                      fs-15
                      pa-1
                      fw-400
                      justify-end
                      text-right
                    "
                  ></div>
                  <div
                    v-if="message.attachments.length"
                    class="
                      d-flex
                      pa-1
                      align-center
                      justify-end
                      fullwidth
                      secondary--text
                    "
                  >
                    <v-btn :href="message.attachments[0].url" icon>
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-btn>
                    <Attachment
                      :item="message.attachments[0]"
                      styles="max-width:90%; height:auto;max-height: 100px;"
                      prefer-thumb
                    ></Attachment>
                  </div>
                </v-list-item-content>
                <v-badge
                  bordered
                  bottom
                  color="success"
                  dot
                  offset-x="25"
                  offset-y="15"
                >
                  <v-list-item-avatar>
                    <v-img
                      :lazy-src="settings.loader"
                      contain
                      :src="message.causer.image_url"
                    ></v-img>
                  </v-list-item-avatar>
                </v-badge>
              </v-list-item>
            </v-hover>
          </template>
        </v-list>
      </v-sheet>
      <v-sheet
        v-else-if="!loading"
        height="100%"
        class="d-flex align-center mx-auto justify-center"
      >
        <Empty
          headline="No comment yet"
          icon="mdi-message-bulleted-off"
        ></Empty>
      </v-sheet>
    </v-card-text>
    <v-card-actions class="comment-chatfield">
      <ChatField
        :style="chatfieldStyles"
        @send-message="addComment"
        :small="!mdAndUp"
        :mentionables="mentionables"
      ></ChatField>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ChatField from '../ChatBox/ChatField.vue'
import request from '@/services/axios_instance'
import DeleteDialog from '@/common/DeleteDialog.vue'
import OverlayLoader from '@/common/Loader/OverlayLoader.vue'
import Attachment from '../Attachment.vue'
export default {
  components: { ChatField, OverlayLoader, Attachment },
  inheritAttrs: false,
  name: 'CommentV2',
  props: {
    id: { type: Number, required: true },
    type: { type: String, required: true },
    bodyMinHeight: { type: Number, default: 400 },
    bodyMaxHeight: { type: String, default: 'auto' },
    deletable: { type: Boolean, default: true },
    withAvatar: { type: Boolean, default: false },
    hasHeader: { type: Boolean, default: false },
    chatfieldStyles: { type: String }
  },
  data: () => ({
    loading: false,
    loading_more: false,
    mentionables: [],
    delete_comment_dialog: false,
    comments: [],
    comment: '',
    attachment: null,
    btnloading: false,
    hover: false,
    activeComment: null,
    next_page_url: null,
    counterReset: 0
  }),
  watch: {
    id: {
      handler: function (val) {
        this.fetchComments()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    isEmptyComment() {
      return !this.comment || this.comment === ''
    },
    fetchApi() {
      return `api/comments/${this.id}/${this.type}`
    },
    deleteApi() {
      return `api/comments/${this.activeComment.id}/${this.type}`
    },
    addApi() {
      return `api/comments/${this.id}/${this.type}`
    },
    uploadApi() {
      return `api2/attachments/dropzone` //`api/file/image-upload`
    }
  },
  methods: {
    can_delete_comment(comment) {
      if (!this.deletable) return false
      if (this.user.is_admin) return true
      return comment.causer.id === this.user.id
    },
    confirmDelete(item) {
      this.activeComment = item
      this.delete_comment_dialog = true
    },
    addComment(payload) {
      if (!payload) {
        return
      }
      let formData = new FormData()
      formData.append('body', payload.message)
      if (payload.files.length) {
        formData.append('file', payload.files[0])
      }
      request
        .post(this.addApi, formData)
        .then(({ data }) => {
          this.comments.push(data)
          this.comment = ''
          this.counterReset += 1
        })
        .finally(() => this.$event.$emit('btnsending_off'))
    },
    confirmed_delete_comment() {
      request
        .delete(this.deleteApi)
        .then(({ data }) => {
          let index = this.comments.findIndex(
            (cm) => cm.id === this.activeComment.id
          )
          if (~index) {
            this.comments.splice(index, 1)
            this.activeComment = null
          }
          this.delete_comment_dialog = false
        })
        .finally(() => (this.btnloading = false))
    },
    fetchComments() {
      this.loading = true
      request
        .get(this.fetchApi)
        .then(({ data }) => {
          this.comments = data.data.reverse()
          this.next_page_url = data.next_page_url
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchMoreComments() {
      this.loading_more = true
      request
        .get(this.next_page_url)
        .then(({ data }) => {
          this.comments.unshift(...data.data.reverse())
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading_more = false))
    }
  }
}
</script>

<style lang="scss" scoped>
.comments {
  .comments-body {
    overflow-y: auto;
  }
  .comment-chatfield {
    width: 100%;
    height: 100px;
  }
}
</style>
